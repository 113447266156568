import { ImageBrowseMan, ImageBagSkate, ImagePig } from '../../assets';
import { useStoreCountContext } from '../../context';
import { CmsContent } from '../CmsContent';
import { useHowItWorksSection } from './HowItWorksSection.hooks';
import {
	Wrapper,
	Steps,
	Step,
	StepImage,
	IntroCmsContent,
} from './HowItWorksSection.parts';

export function HowItWorksSection() {
	const { content } = useHowItWorksSection();
	const storeCount = useStoreCountContext();

	return (
		<Wrapper>
			{content.data?.home_how_it_works_intro && (
				<IntroCmsContent
					markdown={content.data?.home_how_it_works_intro}
					replacements={{ storeCount: storeCount.text }}
				/>
			)}

			<Steps>
				<Step>
					<StepImage uri={ImageBrowseMan} />
					{content.data?.home_how_it_works_1 && (
						<CmsContent markdown={content.data?.home_how_it_works_1} />
					)}
				</Step>
				<Step>
					<StepImage uri={ImageBagSkate} />
					{content.data?.home_how_it_works_2 && (
						<CmsContent markdown={content.data?.home_how_it_works_2} />
					)}
				</Step>
				<Step>
					<StepImage uri={ImagePig} />
					{content.data?.home_how_it_works_3 && (
						<CmsContent markdown={content.data?.home_how_it_works_3} />
					)}
				</Step>
			</Steps>
		</Wrapper>
	);
}
