import {
	Drawer,
	Separator,
	type DrawerProps,
	useAuthContext,
	ActivePathAnchor,
	useNotificationContext,
	StoreSearch,
	Waiter,
} from '@mybonus/ui';
import { useLocation } from 'react-router-dom';

import { AccountOverview } from './AccountOverview';
import { LoginButtons } from './LoginButtons';
import {
	Menu,
	IconMenu,
	NotificationMenuItem,
	FavoritesMenuItem,
	AccountMenuItem,
	SettingsMenuItem,
	DrawerContent,
} from './MenuDrawer.parts';

const mainLinks = [
	{ path: '/browse', text: 'Butiker' },
	{ path: '/campaigns', text: 'Kampanjer' },
	{ path: '/recruitment', text: 'Bonus Buddies' },
	{ path: '/support', text: 'Kundservice' },
];

const guestLinks = [
	{ path: '/app', text: 'Ladda ner Appen' },
	{ path: '/plugin', text: 'Installera Autobäring' },
];

export type MenuDrawerProps = DrawerProps;

export function MenuDrawer(props: MenuDrawerProps) {
	const auth = useAuthContext();
	const location = useLocation();
	const notification = useNotificationContext();

	function renderMenuList(items: { path: string; text: string }[]) {
		return items.map((item, i) => (
			<ActivePathAnchor key={i} to={{ local: item.path }} active={location.pathname === item.path}>
				{item.text}
			</ActivePathAnchor>
		));
	}

	return (
		<Drawer {...props}>
			<DrawerContent>
				<Waiter isLoading={auth.isLoading}>
					{auth.account ? <AccountOverview /> : <LoginButtons />}

					<StoreSearch />
					<Separator />
					<Menu>{renderMenuList(mainLinks)}</Menu>
					<Separator />

					{auth.account ? (
						<IconMenu>
							<AccountMenuItem />
							<NotificationMenuItem count={notification.unreadCount} />
							<FavoritesMenuItem />
							<SettingsMenuItem />
						</IconMenu>
					) : (
						<Menu>{renderMenuList(guestLinks)}</Menu>
					)}
					<Separator />
				</Waiter>
			</DrawerContent>
		</Drawer>
	);
}
