import {
	styled,
	Box,
	CashbackGuideList,
	FloatBox,
	Heading,
	Paragraph,
	Container,
	PageContainer,
} from '@mybonus/ui';

import { BulletSection } from './BulletSection';
import { StoreCard } from './StoreCard';
import { StoreCover } from './StoreCover';

export const $PageContainer = styled(PageContainer).attrs((props) => ({
	...props,
	padding: false,
}))`
	padding-top: 0;
`;

export const Page = styled(Box)`
	display: grid;
	grid-template-rows: 120px;
	background-color: ${({ theme }) => theme.color.lighterGray.base};
	padding-bottom: ${({ theme }) => theme.space.xl}px;
`;

export const $StoreCover = styled(StoreCover)`
	min-height: 316px;
`;

export const Layout = styled(Container)`
	z-index: 1;
	display: grid;
	grid-gap: ${({ theme }) => theme.space.md}px;
	align-items: start;

	@media ${({ theme }) => theme.device.laptop} {
		grid-template-columns: 1fr 2fr;
		grid-gap: ${({ theme }) => theme.space.lg}px;
		grid-template-rows: repeat(8, auto);
	}
`;

export const $StoreCard = styled(StoreCard)`
	grid-row: 1 / -1;
`;

export const $CashbackGuideList = styled(FloatBox).attrs((props) => ({
	...props,
	children: <CashbackGuideList />,
}))`
	grid-column: 1 / 1;
`;

export const Main = styled(FloatBox)`
	padding: 0;

	grid-row: 2 / 3;

	@media ${({ theme }) => theme.device.laptop} {
		grid-column: 2 / -1;
		grid-row: 1 / -1;
	}
`;

export const Title = styled(Heading).attrs((props) => ({
	...props,
	level: 1,
}))`
	margin-top: 0;

	padding: ${({ theme }) => theme.space.lg}px ${({ theme }) => theme.space.md}px
		0;

	@media ${({ theme }) => theme.device.tablet} {
		padding: ${({ theme }) => theme.space.lg}px
			${({ theme }) => theme.space.lg}px 0;
	}
`;

export const Description = styled(Paragraph)`
	padding: 0 ${({ theme }) => theme.space.md}px 0;

	@media ${({ theme }) => theme.device.tablet} {
		padding: 0 ${({ theme }) => theme.space.lg}px 0;
	}
`;

const BaseBulletSection = styled(BulletSection)`
	padding: 0 ${({ theme }) => theme.space.md}px
		${({ theme }) => theme.space.md}px;

	@media ${({ theme }) => theme.device.tablet} {
		padding: 0 ${({ theme }) => theme.space.lg}px
			${({ theme }) => theme.space.md}px;
	}
`;

export const TermsBulletSection = styled(BaseBulletSection)``;

export const CampaignBulletSection = styled(BaseBulletSection).attrs(
	(props) => ({
		...props,
		noIndent: true,
	}),
)`
	background-color: ${({ theme }) => theme.color.lightOrange.base};
	padding-top: ${({ theme }) => theme.space.sm}px;
	margin-bottom: ${({ theme }) => theme.space.md}px;
`;

export const CampaignCardItem = styled(Box)`
	padding: ${({ theme }) => theme.space.sm}px 0;
`;
