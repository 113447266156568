import { z } from 'zod';

import { SingleHero } from './hero';
import { LocalizedText } from './internationalization';

export const ListAdminPage = z.object({
	id: z.number(),
	slug: z.string(),
	active: z.boolean(),
});
export type ListAdminPage = z.infer<typeof ListAdminPage>;

export const SingleAdminPage = z.object({
	id: z.number(),
	slug: z.string(),
	tagId: z.number(),
	heroId: z.number().nullable(),
	content: LocalizedText,
	active: z.boolean(),
});
export type SingleAdminPage = z.infer<typeof SingleAdminPage>;

export const SinglePage = z.object({
	id: z.number(),
	slug: z.string(),
	tagId: z.number(),
	hero: SingleHero.nullable(),
	content: z.string(),
});
export type SinglePage = z.infer<typeof SinglePage>;
