import { Center, CmsContent, CampaignCard } from '@mybonus/ui';

import { useStoreView } from './StoreView.hooks';
import {
	Page,
	$StoreCover,
	Layout,
	$CashbackGuideList,
	Main,
	$StoreCard,
	Title,
	Description,
	TermsBulletSection,
	CampaignBulletSection,
	CampaignCardItem,
	$PageContainer,
} from './StoreView.parts';

export function StoreView() {
	const { store, storeQuery, campaigns } = useStoreView();

	if (store === null) {
		return <></>;
	}

	return (
		<$PageContainer>
			<Page>
				<$StoreCover backgroundImageUrl={store?.coverUrl || ''} />
				<Center>
					<Layout>
						<$StoreCard store={store} isLoading={storeQuery.isLoading} />
						<$CashbackGuideList />
						<Main>
							{store && (
								<>
									<Title>{store.name}</Title>
									<Description>{store.description}</Description>
									{store.termsSpecial && (
										<TermsBulletSection heading="Särskilda villkor">
											<CmsContent html={store.termsSpecial} />
										</TermsBulletSection>
									)}
									{!!campaigns.length && (
										<CampaignBulletSection heading="Pågående kampanjer">
											{campaigns.map((campaign) => (
												<CampaignCardItem key={campaign.id}>
													<CampaignCard store={store} campaign={campaign} big navigateOut />
												</CampaignCardItem>
											))}
										</CampaignBulletSection>
									)}
									{store.termsTime && (
										<TermsBulletSection heading="När blir min återbäring registrerad?">
											<CmsContent html={store.termsTime} />
										</TermsBulletSection>
									)}
									{store.termsWithdrawal && (
										<TermsBulletSection heading="När blir min bonus redo för utbetalning?">
											<CmsContent html={store.termsWithdrawal} />
										</TermsBulletSection>
									)}
								</>
							)}
						</Main>
					</Layout>
				</Center>
			</Page>
		</$PageContainer>
	);
}
