import { config } from '@mybonus/public';

import { useCmsContent } from '../../hooks';

export function useHowItWorksSection() {
	const content = useCmsContent({
		codes: [
			'home_how_it_works_intro',
			'home_how_it_works_1',
			'home_how_it_works_2',
			'home_how_it_works_3',
		] as const,
		languageCode: config.defaultLanguageCode,
	});

	return { content };
}
