import { useAPI, useQuery, useHead, useAuthContext } from '@mybonus/ui';
import { useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

export function useStoreView() {
	const auth = useAuthContext();
	const { api } = useAPI();
	const params = useParams();
	const head = useHead();
	const navigate = useNavigate();

	const storeInput = useMemo(() => ({ id: Number(params.id) }), [params.id]);
	const campaignsInput = useMemo(
		() => ({
			filter: {
				storeId: Number(params.id),
			},
		}),
		[params.id],
	);

	const opts = useMemo(
		() => ({
			refreshFromClient: !!auth.account,
		}),
		[auth.account],
	);

	const storeQuery = useQuery(
		'guest.store.single',
		(args) => api.guest.store.single.query(args),
		storeInput,
		opts,
	);
	const campaignsQuery = useQuery(
		'guest.campaign.list',
		(args) => api.guest.campaign.list.query(args),
		campaignsInput,
		opts,
	);

	const store = storeQuery.data;
	const campaigns = campaignsQuery.data?.items || [];

	if (store) {
		const offer = (store.bestOffer?.text || '').toLowerCase();
		head.setTitle(`${store.name} - Handla och få ${offer} återbäring hos MyBonus`);
	}

	useEffect(() => {
		if (store === null) {
			navigate('/404');
		}
	}, [store]);

	return {
		store,
		storeQuery,
		campaigns,
		campaignsQuery,
	};
}
