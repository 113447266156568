import {
	createGlobalFonts,
	useScrollToTop,
	mainTheme,
	APIProvider,
	AuthProvider,
	FavoriteProvider,
	AccountProvider,
	NotificationProvider,
	StoreCountProvider,
	ThemeProvider,
	FacebookAuthProvider,
	ToastsProvider,
	ToastsOverlay,
} from '@mybonus/ui';
import { Outlet } from 'react-router-dom';

import { client } from '../api/client';
import {
	GlobalReset,
	Header,
	Footer,
	MonitoringPersonalization,
	ConsentPopup,
} from '../components';
import { env } from '../shared/env';
import { Centered, Layout } from './App.parts';

const GlobalFonts = createGlobalFonts('/assets/fonts');

export function App() {
	useScrollToTop({ onLocationChange: true });
	return (
		<ThemeProvider theme={mainTheme}>
			<GlobalFonts />
			<GlobalReset />
			<ConsentPopup />

			<APIProvider client={client}>
				<ToastsProvider>
					<AuthProvider>
						<FacebookAuthProvider fbAppId={env.FB_APP_ID}>
							<MonitoringPersonalization />
							<AccountProvider>
								<NotificationProvider>
									<FavoriteProvider>
										<StoreCountProvider>
											<Layout>
												<Header />
												<Centered>
													<Outlet />
												</Centered>
												<Footer />
												<ToastsOverlay />
											</Layout>
										</StoreCountProvider>
									</FavoriteProvider>
								</NotificationProvider>
							</AccountProvider>
						</FacebookAuthProvider>
					</AuthProvider>
				</ToastsProvider>
			</APIProvider>
		</ThemeProvider>
	);
}
