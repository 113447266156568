import {
	styled,
	GreenGradient,
	Logo,
	Box,
	HamburgerButton,
	Container,
	Anchor,
	NotificationPill,
	FavoritePill,
	Column,
	Row,
} from '@mybonus/ui';

import { SearchPill } from './SearchPill';

export const Position = styled(Column)`
	position: fixed;
	z-index: 2;
	top: 0;
	width: 100%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.18);
`;

export const Gradient = styled(GreenGradient)`
	height: 60px;
	display: flex;
	justify-content: center;
	width: 100%;

	@media ${({ theme }) => theme.device.laptop} {
		height: 80px;
	}
`;

export const Left = styled(Box)`
	flex: 1;
	display: flex;
`;

const $Logo = styled(Logo)`
	width: 128px;
	max-height: 20px;
	padding: ${({ theme }) => theme.space.sm}px 0;

	@media ${({ theme }) => theme.device.laptop} {
		width: 196px;
		max-height: 30px;
	}
`;

export const LogoLink = styled(Anchor).attrs((props) => ({
	...props,
	children: <$Logo />,
	to: { local: '/' },
}))``;

export const Spacer = styled(Box)`
	flex: 0 0 60px;

	@media ${({ theme }) => theme.device.laptop} {
		flex-basis: 120px;
	}
`;

export const HamburgerIcon = styled(HamburgerButton)`
	@media ${({ theme }) => theme.device.laptop} {
		display: none;
	}
`;

export const AccountWrapper = styled(Box)`
	display: none;

	@media ${({ theme }) => theme.device.laptop} {
		display: flex;
	}
`;

export const $Container = styled(Container)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const $NotificationPill = styled(NotificationPill)`
	margin-left: ${({ theme }) => theme.space.sm}px;
`;

export const $FavoritePill = styled(FavoritePill)`
	margin-left: ${({ theme }) => theme.space.sm}px;
`;

export const $SearchPill = styled(SearchPill)`
	margin-left: ${({ theme }) => theme.space.sm}px;
`;

export const LoginButtonWrapper = styled(Row)`
	> * {
		margin-right: ${({ theme }) => theme.space.md}px;

		&:last-child {
			margin-right: 0;
		}
	}
`;
