import { PlatformAlias, config } from '@mybonus/public';
import {
	useAPI,
	useRedirect,
	useMutation,
	useUrl,
	useCountdown,
	APIInput,
	APIOutput,
} from '@mybonus/ui';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { MemberViewProps } from './MemberView';

export function useMemberView(props: MemberViewProps) {
	const params = useParams();
	const url = useUrl();
	const redirect = useRedirect();
	const navigate = useNavigate();
	const storeId = props.store?.id;
	const campaignId = params.campaignId ? Number(params.campaignId) : undefined;
	const countdown = useCountdown(
		() => {
			if (props.store) {
				navigate(
					config.urls.store({
						storeId: props.store.id,
						storeName: props.store.name,
					}),
					{ replace: true },
				);
			}
		},
		{ durationSeconds: 15 },
	);

	const { api } = useAPI();
	const [showAccept, setShowAccept] = useState(!!props.link);
	const [hasRedirected, setHasRedirected] = useState(false);

	const registration = useMutation<
		APIInput['user']['redirect']['register'],
		APIOutput['user']['redirect']['register']
	>((args) => api.user.redirect.register.mutate(args));

	useEffect(() => {
		if (!registration.isLoading && registration.data) {
			const isByLink = !!props.link;
			redirect.toURL(registration.data, { follow: isByLink });
			setHasRedirected(true);
			countdown.start();
		}
	}, [registration.data, props.link]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowAccept(true);
		}, 5000);

		return () => clearTimeout(timeout);
	}, []);

	// Redirect right away if the user arrives at /out with code (from app)
	// TODO: The app should use the mutation directly, and not rely on the web
	// for redirecting.
	useEffect(() => {
		if (props.link && storeId) {
			const platformAliasResult = PlatformAlias.safeParse(url.queryObject.platform_alias);
			const platformAlias = platformAliasResult.success ? platformAliasResult.data : 'UNKNOWN';

			registration.mutate({
				storeId,
				campaignId,
				relay: {
					userLink: props.link,
					platformAlias,
				},
			});
		}
	}, [props.link, storeId]);

	function handleAccept() {
		if (storeId) {
			registration.mutate({ storeId, campaignId });
		}
	}

	return {
		hasRedirected,
		showAccept,
		handleAccept,
		registration,
		countdown,
	};
}
