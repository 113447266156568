export const color = {
	transparent: create([0, 0, 100, 0]),
	white: create([0, 0, 100, 1]),
	whiteBrightened: create([0, 0, 100, 0.12]),
	lightGray: create([0, 0, 92, 1]),
	lighterGray: create([0, 0, 97, 1]),
	midGray: create([0, 0, 75, 1]),
	gray: create([0, 0, 40, 1]),
	darkGray: create([0, 0, 20, 1]),
	green: create([153, 84, 39, 1]),
	lightGreen: create([153, 84, 93, 1]),
	red: create([3, 97, 54, 1]),
	orange: create([18, 90, 49, 1]),
	yellow: create([33, 100, 57, 1]),
	lightOrange: create([18, 90, 95, 1]),
	facebook: create([220, 44, 41, 1]),
	facebookMessenger: create([210, 99, 49, 1]),
	bankid: create([199, 53, 20, 1]),
	primaryGradientFrom: create([166, 99, 29, 1]),
	primaryGradientTo: create([142, 76, 48, 1]),
};

export type ColorMap = {
	base: string;
	active: string;
};

export function create(hsla: [number, number, number, number]): ColorMap {
	return {
		base: toHSLA(hsla),
		active: toHSLA([hsla[0], hsla[1], hsla[2] + 2, hsla[3]]),
	};
}

function toHSLA(hsla: [number, number, number, number]): string {
	return `hsla(${hsla[0]}, ${hsla[1]}%, ${hsla[2]}%, ${hsla[3]})`;
}
