import { config } from '@mybonus/public';
import { useAPI, useClientStorage, useQuery } from '@mybonus/ui';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function useRegisterRecruitmentView() {
	const params = useParams();
	const { api } = useAPI();
	const navigate = useNavigate();
	const storage = useClientStorage<string>({
		key: config.storage.keys.recruitmentCode,
		type: 'session',
	});
	const code: string | null = typeof params.code === 'string' ? params.code : null;
	const affiliateCampaignInput = useMemo(() => ({ code }), [code]);

	const affiliateCampaign = useQuery(
		'guest.affiliate.campaign.landingPageByCode',
		(args) => api.guest.affiliate.campaign.landingPageByCode.query({ code: args.code || '' }),
		affiliateCampaignInput,
		{ preventAutoload: !code },
	);

	useEffect(() => {
		if (code) {
			storage.setItem(code);
		}
	}, []);

	useEffect(() => {
		if (affiliateCampaign.data) {
			if (affiliateCampaign.data.campaign?.landingPageUrl) {
				navigate(affiliateCampaign.data.campaign.landingPageUrl);
			} else {
				navigate('/');
			}
		}
	}, [affiliateCampaign.data]);

	return {
		error: affiliateCampaign.error,
		isLoading: affiliateCampaign.isLoading,
	};
}
