import { useLocation } from 'react-router-dom';

import { Wrapper, $ActivePathAnchor } from './MenuLinks.parts';

const items = [
	{ path: '/browse', text: 'Butiker' },
	{ path: '/campaigns', text: 'Kampanjer' },
	{ path: '/recruitment', text: 'Bonus Buddies' },
	{ path: '/support', text: 'Kundservice' },
];

export function MenuLinks() {
	const location = useLocation();

	return (
		<Wrapper>
			{items.map((item, i) => (
				<$ActivePathAnchor
					key={i}
					to={{ local: item.path }}
					active={location.pathname === item.path}
				>
					{item.text}
				</$ActivePathAnchor>
			))}
		</Wrapper>
	);
}
