import { ImagePhoneCover } from '@mybonus/ui';

import { $Center, $CoverSection, $StoreSearch } from './SearchCover.parts';

export function SearchCover() {
	return (
		<$CoverSection
			backgroundImageUrl={ImagePhoneCover}
			overlayColor="rgba(16, 182, 106, 0.25)"
		>
			<$Center vertical>
				<$StoreSearch />
			</$Center>
		</$CoverSection>
	);
}
