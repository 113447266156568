import { SortLabel, CmsContent, CampaignGrid, Waiter, SectionHero } from '@mybonus/ui';

import { useCampaignsView } from './CampaignsView.hooks';
import { Filters, Wrapper, $PageContainer, $PageTitle } from './CampaignsView.parts';

export function CampaignsView() {
	const { campaigns, isLoading, sortOrder, setSortBy, sortBy } = useCampaignsView();

	return (
		<Wrapper>
			<SectionHero section="CAMPAIGNS" />
			<$PageContainer>
				<$PageTitle>
					<CmsContent code="campaigns_intro" />
				</$PageTitle>

				<Filters>
					<SortLabel
						options={sortOrder}
						onSelect={(id: string) => setSortBy(id as typeof sortOrder[number]['id'])}
						selected={sortBy}
					/>
				</Filters>

				<Waiter isLoading={isLoading}>
					<CampaignGrid campaigns={campaigns} />
				</Waiter>
			</$PageContainer>
		</Wrapper>
	);
}
