import { RemoteImage, Box } from '../../primitives';
import { styled } from '../../theme';
import type { CoverSectionProps } from './CoverSection';

export const Wrapper = styled(Box)`
	display: grid;
	width: 100%;
`;

export const Image = styled(RemoteImage).attrs((props) => ({
	...props,
}))`
	object-fit: cover;
	width: 100%;
	height: 100%;
	overflow: hidden;
	grid-row: 1 / -1;
	grid-column: 1 / -1;
`;

export const Overlay = styled(Box)<Pick<CoverSectionProps, 'overlayColor'>>`
	grid-row: 1 / -1;
	grid-column: 1 / -1;
	z-index: 1;
	background-color: ${(props) => props.overlayColor || 'none'};
`;
