import { useMemo } from 'react';

import { useAPI, useQuery, APIInput } from '../../api';
import type { SectionHeroProps } from './SectionHero';

export function useSectionHero(props: SectionHeroProps) {
	const { api } = useAPI();

	const input = useMemo<APIInput['guest']['hero']['section']>(
		() => ({ section: props.section }),
		[props.section],
	);

	const hero = useQuery(
		'guest.hero.section',
		(args) => api.guest.hero.section.query(args),
		input,
	);

	return { hero };
}
