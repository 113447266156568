import type { SingleHero } from '@mybonus/public';

import { useDevice } from '../../hooks';
import { Box } from '../../primitives';
import { mainTheme, invertTheme, ThemeProvider } from '../../theme';
import {
	$CoverSection,
	Headline,
	Description,
	Content,
	Wrapper,
	Button,
} from './Hero.parts';

export type HeroProps = {
	hero: SingleHero;
};

export function Hero(props: HeroProps) {
	const {
		headline,
		description,
		theme: preferredTheme,
		coverUrl,
		button,
	} = props.hero;
	const device = useDevice();

	return (
		<$CoverSection
			backgroundImageUrl={
				(device.min('laptop') ? coverUrl.desktop : coverUrl.mobile) || undefined
			}
		>
			<ThemeProvider
				theme={preferredTheme === 'invert' ? invertTheme : mainTheme}
			>
				<Wrapper>
					<Content>
						{headline && <Headline>{headline}</Headline>}
						{description && <Description>{description}</Description>}
						{button && (
							<Box>
								<Button text={button.label} to={{ local: button.url }} />
							</Box>
						)}
					</Content>
				</Wrapper>
			</ThemeProvider>
		</$CoverSection>
	);
}
