import {
	styled,
	css,
	Gradient,
	JumboTitle,
	Paragraph,
	LocalImage,
	ImageAppHand,
	CmsContent,
	Box,
} from '@mybonus/ui';

export const Background = styled(Gradient).attrs((props) => ({
	...props,
	colors: ['#0dae7913', '#18cb6336'],
	angle: 30,
}))`
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const Wrapper = styled(Box)`
	display: flex;
	flex-direction: column;
	max-width: 960px;
	width: 100%;
	${({ theme }) =>
		css`
			padding: ${theme.space.md}px ${theme.space.md}px 0 ${theme.space.md}px;
		`};

	@media ${({ theme }) => theme.device.tablet} {
		flex-direction: row;
	}
`;

export const Title = styled(JumboTitle)`
	color: ${({ theme }) => theme.color.green.base};
	font-size: 38px;

	@media ${({ theme }) => theme.device.laptop} {
		font-size: 48px;
	}
`;

export const ImageWrapper = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: flex-start;
`;

export const Image = styled(LocalImage).attrs((props) => ({
	...props,
	uri: ImageAppHand,
}))`
	max-width: 500px;
	width: 100%;
`;

export const $CmsContent = styled(CmsContent).attrs((props) => ({
	...props,
	code: 'home_top_section',
	components: {
		h1: (props: any) => <Title {...props} />,
		p: (props: any) => <Paragraph size="lg" {...props} />,
	},
}))`
	width: 100%;
	@media ${({ theme }) => theme.device.tablet} {
		${({ theme }) =>
			css`
				padding: ${theme.space.xl}px ${theme.space.lg}px ${theme.space.lg}px 0;
			`};
	}
`;
