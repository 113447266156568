import { MetaTag } from '../types';
import { slugify } from '../utils/data/transform';

const websiteName = 'MyBonus.com';

const webSEODefaultDescription =
	'Spara tusenlappar varje år när du handlar genom MyBonus. Vi ger dig en slant tillbaka, oavsett om du handlar på rea eller till ordinarie pris. Det blir något extra som du inte skulle ha fått annars - lite som grädden på moset faktiskt. Gå med idag – det är helt gratis!';

const webDefaultMetaTags: (opts: { webBaseUrl: string; cdnUrl: string }) => MetaTag[] = ({
	webBaseUrl,
	cdnUrl,
}) => [
	{
		type: 'name',
		key: 'description',
		content: webSEODefaultDescription,
	},
	{ type: 'property', key: 'og:locale', content: 'sv_SE' },
	{ type: 'property', key: 'og:type', content: 'website' },
	{
		type: 'property',
		key: 'og:title',
		content: 'Vi ger dig grädden på moset när du handlar online!',
	},
	{ type: 'property', key: 'og:description', content: webSEODefaultDescription },
	{
		type: 'property',
		key: 'og:image',
		content: `${cdnUrl}/assets/images/social-share-app.jpg`,
	},
	{ type: 'property', key: 'og:image:width', content: '1200' },
	{ type: 'property', key: 'og:image:height', content: '630' },
	{ type: 'property', key: 'og:site_name', content: websiteName },
	{ type: 'property', key: 'og:url', content: webBaseUrl },
];

export const config = {
	minimumCashbackForWithdrawal: 100,
	defaultCurrencyCode: 'SEK',
	defaultLanguageCode: 'sv',
	defaultLocale: 'sv-SE',
	urls: {
		store: (opts: { webBaseUrl?: string; storeId: number; storeName: string }) =>
			`${opts.webBaseUrl || ''}/store/${opts.storeId}/${slugify(opts.storeName)}`,
		redirect: (opts: { webBaseUrl?: string; storeId: number; campaignId?: number }) =>
			`${opts.webBaseUrl || ''}/out/${opts.storeId}${
				opts.campaignId ? `?campaignId=${opts.campaignId}` : ''
			}`,
		// The storeId parameter (in v1) made the user end up on the specific store page after recruitment
		// invite link was used.
		invite: (webBaseUrl: string, code: string, storeId?: number): string =>
			`${webBaseUrl}/r/${code}${storeId ? `?store=${storeId}` : ''}`,
		// TODO: Update this to match new web
		passwordReset: (webBaseUrl: string, code: string): string => `${webBaseUrl}/l/${code}`,
		accountRemoval: (webBaseUrl: string, code: string): string =>
			`${webBaseUrl}/account-removal/${code}`,
		emailConfirmation: (webBaseUrl: string, code: string): string => `${webBaseUrl}/n/${code}`,
		defaultAvatarUrl: (cdnUrl: string): string => `${cdnUrl}/images/layout/profile_default.png`,
		// TODO: This is not used/implemented in web 2.0,
		// let it go to 404 until we clean it up.
		unsubscribeTip: (webBaseUrl: string, email: string): string =>
			`${webBaseUrl}/unsubscribe?e=${email}`,
		login: (opts?: { webBaseUrl?: string; redirectBackToPath?: string }) =>
			`${opts?.webBaseUrl || ''}/login${
				opts?.redirectBackToPath ? `?redirect=${encodeURIComponent(opts.redirectBackToPath)}` : ''
			}`,
		legacySessionUserId: (v1BaseUrl: string) => `${v1BaseUrl}/index/session-user-id`,
		claim: (webBaseUrl?: string) => `${webBaseUrl || ''}/account/claim`,
		terms: (webBaseUrl?: string) => `${webBaseUrl || ''}/terms`,
		privacyPolicy: (webBaseUrl?: string) => `${webBaseUrl || ''}/privacy`,
		cookiePolicy: (webBaseUrl?: string) => `${webBaseUrl || ''}/cookies`,
		helpCenter: () => 'https://mybonus.zendesk.com/hc',
	},
	avatarSize: 256,
	claim: {
		trackEmail: (trackId: string): string => `${trackId}@track.mybonus.com`,
		minAge: '48h',
		maxAge: '60d',
	},
	company: {
		name: 'MyBonus AB',
		street: 'Fabriksstråket 32',
		zip: '433 76',
		city: 'Jonsered',
		countryCode: 'SE',
		orgNo: '556994-6832',
	},
	customerServiceEmail: 'kundservice@mybonus.com',
	defaultEmailFromAddress: {
		fullname: 'MyBonus',
		email: 'no-reply@mybonus.com',
	},
	identification: {
		leeway: '10m',
	},
	reward: {
		firstLoginOnPlatform: {
			rewardDuration: '7 days',
			cashbackShareReward: 100,
			platformAliases: ['EXT_CHROME'],
		},
	},
	recruitmentCodeLength: 6,
	passwordResetCodeValidPeriod: '24h',
	accountRemovalCodeValidPeriod: '24h',
	fulfillEmailChangeCodeValidDays: 2,
	baseCashbackShare: 50,
	storage: {
		keys: {
			recruitmentCode: 'recruitmentCode',
			consent: 'consent',
		},
	},
	app: {
		scheme: 'mybonus://',
		android: {
			playStoreUrl: 'https://play.google.com/store/apps/details?id=com.mybonus.app',
		},
		ios: {
			appStoreUrl: 'https://itunes.apple.com/us/app/mybonus/id1447368328',
		},
	},
	plugin: {
		chrome: {
			url: 'https://chrome.google.com/webstore/detail/mybonus-autob%C3%A4ring/cpkgocojmfpjfnbdhodjifclifdkomod',
		},
	},
	web: {
		defaultTitle: websiteName,
		defaultMetaTags: webDefaultMetaTags,
	},
} as const;
